/* eslint-disable no-tabs */
// import { useEffect, useState } from "react";

// import { FormProvider } from "react-hook-form";
// import c from "classnames";

// import { SlapLabel } from "@/components/FormElements/SlapLabel";
// import { SlapLabelVariants } from "@/types/layout";
// import { Icon } from "@/components/FormElements/Icon";
// import { Button } from "@/components/FormElements/Button";
// import { ButtonVariants } from "@/types/inputFields";
// import { LoadingContainer } from "@/components/LoadingContainer";
// import { useABTest } from "@/hooks/useABTest";

// import { usePriceEstimate } from "./usePriceEstimate";
// import { LifeStageSelector } from "./LifeStageSelector";
// import Styles from "./priceEstimate.module.scss";
// import { PriceChangeModal } from "./PriceChangeModal";

// import { ImageAndText } from "../ImageAndText";

type Props = {
	content: any;
};

export const PriceEstimate = ({ content }: Props) => {
	return <></>;

	// const {
	// 	methods,
	// 	handleSubmit,
	// 	onSubmit,
	// 	options,
	// 	error,
	// 	breed,
	// 	lifeStage,
	// 	createPlan,
	// 	reset,
	// 	isMobile,
	// 	openPriceChangeModal,
	// 	isLoading,
	// 	priceChangeModalOpen,
	// 	closePriceChangeModal,
	// 	adultPrice,
	// 	oldPuppyPrice,
	// 	puppyPrice,
	// 	user,
	// } = usePriceEstimate();

	// /** AB TEST */

	// const { startABTest, getABTest } = useABTest();

	// useEffect(() => {
	// 	startABTest({
	// 		testName: "Price estimate",
	// 		forceTest: !!(
	// 			(user?.sales_person_id && process.env.NEXT_PUBLIC_SITE === "sales") ||
	// 			process.env.NEXT_PUBLIC_URL === "https://localhost:3000" ||
	// 			process.env.NEXT_PUBLIC_URL === "https://staging.purepetfood.com"
	// 		),
	// 	});
	// }, [startABTest, user?.sales_person_id]);

	// if (getABTest({ testName: "Price estimate" }) === "Control" || true) {

	// }

	/** AB TEST */

	// return (
	// 	<>
	// 		<ImageAndText
	// 			content={{
	// 				__typename: "",
	// 				id: content.id,
	// 				textOnLeft: content.textOnLeft,
	// 				backgroundImage: content.backgroundImage,
	// 				imageOverlay: (
	// 					<span className={Styles.overlay}>
	// 						<span className={Styles.top}>
	// 							Whisky's plan<div className={Styles.price}>£1.80/day</div>
	// 						</span>
	// 						<span className={Styles.bottom}>
	// 							Rosie's plan<div className={Styles.price}>£1.24/day</div>
	// 						</span>
	// 					</span>
	// 				),
	// 			}}
	// 		>
	// 			<LoadingContainer loading={isLoading}>
	// 				<FormProvider {...methods}>
	// 					<form onSubmit={handleSubmit(onSubmit)}>
	// 						<div className={Styles.container}>
	// 							<div
	// 								className={c(Styles.mainContent, {
	// 									[Styles.isHidden]:
	// 										!!adultPrice || !!oldPuppyPrice || !!puppyPrice,
	// 								})}
	// 							>
	// 								<div>
	// 									<h2 className="text-center narrow-bottom">
	// 										{content.heading}
	// 									</h2>
	// 									<p className="text-center">{content.caption}</p>
	// 									<LifeStageSelector options={options} />
	// 								</div>
	// 								{!!error && (
	// 									<SlapLabel variant={SlapLabelVariants.errors}>
	// 										{error}
	// 									</SlapLabel>
	// 								)}
	// 							</div>
	// 							<div
	// 								className={c(Styles.overlay, {
	// 									[Styles.isShown]:
	// 										!!adultPrice || !!oldPuppyPrice || !!puppyPrice,
	// 								})}
	// 							>
	// 								<div className="text-center">
	// 									<h2 className="text-center">Your results</h2>
	// 									<Icon
	// 										icon={
	// 											lifeStage === "young_puppy"
	// 												? "IconFunnelPuppy"
	// 												: lifeStage === "puppy"
	// 												? "IconFunnelOldPuppy"
	// 												: "IconFunnelAdult"
	// 										}
	// 										height="96px"
	// 										width="96px"
	// 									/>
	// 									<p className="tiny-bottom">You could feed your</p>
	// 									<h3 className="text-center tiny-bottom">
	// 										{lifeStage === "adult" && "Adult "}
	// 										{breed}
	// 										{lifeStage === "young_puppy" && " young puppy"}{" "}
	// 										{lifeStage === "puppy" && " puppy"}
	// 									</h3>
	// 									<p className="narrow-bottom">for as little as</p>
	// 									<p
	// 										className={c("text-center", {
	// 											"narrow-bottom":
	// 												lifeStage === "young_puppy" || lifeStage === "puppy",
	// 										})}
	// 									>
	// 										<span className={Styles.price}>
	// 											£{puppyPrice || oldPuppyPrice || adultPrice}/day
	// 										</span>
	// 									</p>

	// 									{(lifeStage === "young_puppy" || lifeStage === "puppy") && (
	// 										<p className="link" onClick={openPriceChangeModal}>
	// 											How will my price change as my puppy grows?
	// 										</p>
	// 									)}

	// 									<div className={Styles.buttons}>
	// 										<Button
	// 											onClick={createPlan}
	// 											type="button"
	// 											width={isMobile ? "full" : "relative"}
	// 										>
	// 											Create a tailored plan
	// 										</Button>
	// 										<Button
	// 											onClick={reset}
	// 											type="button"
	// 											variant={ButtonVariants.ghost}
	// 											width={isMobile ? "full" : "relative"}
	// 											leftIcon={{ icon: "Restart" }}
	// 											className="link"
	// 										>
	// 											Start again
	// 										</Button>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						</div>
	// 					</form>
	// 				</FormProvider>
	// 			</LoadingContainer>
	// 		</ImageAndText>
	// 		{adultPrice && (
	// 			<PriceChangeModal
	// 				open={priceChangeModalOpen}
	// 				onClose={closePriceChangeModal}
	// 				adultPrice={adultPrice}
	// 				oldPuppyPrice={oldPuppyPrice}
	// 				puppyPrice={puppyPrice}
	// 				onAccept={createPlan}
	// 				onAcceptText={"Create a tailored plan"}
	// 				heading="As your puppy grows"
	// 			>
	// 				<p>
	// 					Did you know that a growing dog needs more calories per kg of body
	// 					weight than a fully grown dog. When they grow, requiring less
	// 					calories means their daily price is likely to stay the same or even
	// 					reduce.
	// 				</p>
	// 			</PriceChangeModal>
	// 		)}
	// 	</>
	// );
};
